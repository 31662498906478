import React from "react"
import Layout from "../components/layout/layout.component"
import { GlobalStyle } from "../styles/global.style"

export default () => (
  <div>
    <GlobalStyle />
    <Layout>About</Layout>
  </div>
)
